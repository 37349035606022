import { graphql, PageProps } from "gatsby";
import React, { FC } from "react"
import { PageTitle } from "../components/page-title";
import { ContainerCenter } from "../components/container-center";
import { Button } from "../components/button"
import Layout from "../components/layout";
import Seo from "../components/seo";
import * as styles from '../css/category.module.css'

type PageContext={
  category: string
};

const category: FC<PageProps<GatsbyTypes.ServicePageByCategoryQuery, PageContext>>=({data, location, pageContext})=>{
  // const title=`一覧(カテゴリ: ${pageContext['category']})`;
  const title=`${pageContext['category']}`;

  return (
    <Layout location={location} title={title}>
      <Seo title={title} lang="ja" description="description" />
      <PageTitle pageTitle={title} discription="コンテンツ一覧" />
      <ContainerCenter>
        {data.allServicePageJson.nodes.map(value=>(<Button link={`${value.slug}`} text ={`${value.title}`} />))}
      </ContainerCenter>
    </Layout>
  )
}

export default category;

export const pageQuery=graphql`
query ServicePageByCategory($category: String!) {
  allServicePageJson(filter: {category: {eq: $category}}) {
    nodes {
      id
      slug
      title
      category
    }
  }
}
`